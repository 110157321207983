<template>
  <img alt="Vue logo" src="./assets/logo.png" class="Logo">
  <v-container class="text-left Mymargin">
    <v-row v-if="tab === 'Slots'">
      <v-col v-for="(item, index) in 6" :key="index" cols="6" md="4">
        <a href="https://22aud.com" target="_blank">
          <img :alt="`Game ${index + 1}`" :src="require(`@/assets/game${index + 1}.jpg`)" class="Game">
        </a>
      </v-col>
    </v-row>
    <v-row>
      <div class="content">
        <h1>22AUD | 22AUD Casino Online Casino Australia</h1>
        <p>Welcome to 22AUD Official Login Website. Download your IOS or Android App and register an account to join the
          Australia online casino application.</p>
        <p>22AUD is the best online gambling platform of all times in Australia. We are very famous for being so secure
          as well as offering you a quality gaming that is unrivalled. In the virtual world, 22AUD is just like a
          reliable compass. To be honest and provide the fairest deal has made us renowned because we are known to be
          honest and fair.</p>
        <h2>22AUD: The Top &amp; Most Trusted Casino Company In Australia</h2>
        <p>Enjoy thrilling games with the newest technology and convenient interface. Smart players who desire a fun and
          safe gambling place choose 22AUD. You will have an awesome time filled with unmatched experiences by playing
          at 22AUD. Join happy players worldwide, who enjoy the most incredible entertainment at 22AUD and put their
          trust in it. It’s an amazing place to have fun without troubles!</p>
        <h3>Popular 22AUD Games Available In Australia</h3>
        <p>Get to Australia’s 22AUD and have fun in the game world. We have great slot machines and thrilling live
          casino games that everyone adores! Play our most loved games and win big!</p>
        <p><strong>Live Casino: </strong> There is the excitement of 22AUD’s live casino games! Imagine playing with
          actual dealers as if in a bricks-and-mortar casino. It’s not about gaming, it’s interpersonal and palpable.
          However, the internet can be unreliable sometimes causing some connection challenges. Nonetheless, this is
          what makes live play at 22AUD so thrilling! You can have a real casino atmosphere while staying within your
          comfort zone. Hence, if you feel like indulging in interactive real fun, then 22AUD’s live casino is the place
          to be at. Simply ensure that you are ready for some action-packed moments with real dealers every now and then
          losing connection; that’s exactly how funny it can get sometimes!</p>

        <p><strong>Pros:</strong></p>
        <ul>
          <li>Live gaming experience</li>
          <li>Playing with real dealers.</li>
          <li>True Casino Environment.</li>
        </ul>

        <p><strong>Cons:</strong></p>
        <ul>
          <li>Possible interruptions by internet.</li>
          <li>Depending on internet speed.</li>
        </ul>

        <p><strong>Slots:</strong> 22AUD presents the superlative slot machines that are fun filled! You can enjoy a
          range of classic and new games that may pay out huge amounts – we are speaking about millions of dollars. They
          are very easy to play, and you have many options. In the heat of the moment it is easy to get carried away and
          play fast but don’t forget it’s luck that determines whether you win or loss. These devices provide players
          with simple yet exhilarating opportunities for testing one’s fortune and probably hitting jackpots at 22AUD.
          Therefore, if you want an exciting gaming experience with simple rules and great winning possibilities, then
          choose 22AUD’s slots machines!</p>

        <p><strong>Pros:</strong></p>
        <ul>
          <li>Basic gameplay.</li>
          <li>A range of themes and choices.</li>
          <li>Chances for huge winnings.</li>
        </ul>

        <p><strong>Cons:</strong></p>
        <ul>
          <li>Possible rapid playing.</li>
          <li>Luck determines the results.</li>
        </ul>

        <h3>How To Play 22AUD Australian Casino Games?</h3>

        <p>Go to the site or download the app and start playing. Open an account by giving your true details and
          verifying yourself. Once you have logged in, feel free to go around as we have a variety of games including
          live casino games and different <a href="https://22aud.com/slot/">22AUD slots</a>. Ensure that you should be
          able to plan your budget well, select the game that suits you most and play responsibly. You can utilize demo
          versions for practice purposes before venturing into real money betting.</p>

        <p>It is necessary to understand how various games work; that’s why one should familiarize themselves with
          gaming rules and strategies in order to increase chances of winning. With 22AUD’s easy-to-use interface and
          safe system, you are bound to enjoy yourself while playing and stand a chance of winning huge amounts in
          return too.</p>

        <h3>How To Join 22AUD Casino?</h3>

        <p>If you are a person who would like to join 22AUD casino, this is how you can do it. First of all, visit the
          22AUD site or its application and select “Sign Up” or “Join Now.” Fill in the necessary information accurately
          with your correct email address as well as a secure password. Confirm your identity by following any of the
          given methods to finalize the registration process.</p>

        <p>Upon confirmation, check-out our intriguing online casino world. Enjoy safe payments, helpful community that
          assist each other through and numerous playing choices. Participate in 22AUD Casino today for a fun filled and
          probably profitable online gambling experience.</p>

        <h3>2024 Bonuses and Promotions at 22AUD Casino</h3>

        <p>There are rewards for the players that we at 22AUD assure. Be it from when you join us, you will be welcomed
          with an outlandish bonus that will give your bankroll a boost and extend your gameplay. However, that is not
          all! Our ongoing promotions include:</p>

        <ol>
          <li><strong>Reload Bonuses:</strong> This means that if you deposit money in your account, you can receive
            extra credits as bonuses.</li>
          <li><strong>Free Spins:</strong> The best part is that these pokies can help one earn real cash without
            spending a dime.</li>
          <li><strong>Loyalty Program:</strong> Become a VIP member of 22AUD and enjoy more benefits such as;
            personalized promotions, cashbacks, and a dedicated account manager.</li>
        </ol>

        <h3>Customer Support</h3>

        <p>Our dedicated customer support team is here to assist you 24/7. Whatever your question about a game, deposit
          assistance or simply guidance, our friendly and professional staff are always ready to help. You can contact
          us for efficient service either through live chat, email or phone calls.</p>

        <h3>Frequently Asked Questions</h3>

        <h4>Are 22AUD games safe?</h4>

        <p>Yes, security is the first priority at 22AUD. Our games are thoroughly tested to provide a fair and
          transparent gaming environment. Also, your personal information as well as transactions will be secured with
          top level of protection.</p>

        <h4>What sort of games does 22AUD have?</h4>

        <p>22AUD has a wide variety of games like <a href="https://22aud.com//live-casino/">live casino</a> experiences
          and different types of slot machines. Whatever you love real-time communication or spinning reels for
          entertainment sake, 22AUD offers something for everybody.</p>

        <h4>How do I make a deposit and withdraw money on 22AUD?</h4>

        <p>Depositing and withdrawing funds on 22AUD has been made easy. All you have to do is go to the “cashier”
          section, select the payment method you prefer and follow the process of safe transaction. This makes it more
          convenient for you since there are several payment methods provided by 22AUD.</p>

        <h4>Can I play games at 22AUD using my mobile device?</h4>

        <p>Yes, you can access 22AUD from your desktop or mobile. Whether you are at home or on a journey, our
          Australian
          Casino games will offer you an uninterrupted experience that is user-friendly and safe. Click on <a
            href="https://22aud.com/register/SMSRegister">22AUD Register</a></p>
      </div>
    </v-row>

  </v-container>
  <v-footer class="bg-amber-darken-2 text-center d-flex flex-column" style="padding:0">
    <v-container class="text-left" style="padding:16px!important; color:white">

      <v-row>
        <v-col cols="12" md="4">
          <!-- <img alt="Vue logo" src="./assets/logo.png" class="w-75"> -->
          <p class="footer-text">22AUD is a popular online casino in Malaysia and slot game platform that offers a wide
            range of games,
            including slot games, table games, and arcade games.</p>

        </v-col>
        <v-col cols="12" md="4">
          <h1>Payment Support</h1>
          <img alt="Vue logo" src="./assets/visa.png" class="w-100">
        </v-col>
        <v-col cols="12" md="4">
          <h1>Available Games</h1>
          <img alt="Vue logo" src="./assets/game.png" class="w-100">
        </v-col>
      </v-row>
    </v-container>
    <div class="px-4 py-2 bg-blue-darken-4 text-center w-100">
      COPYRIGHT@22aud.com
    </div>
    <br>
  </v-footer>


</template>

<script>
export default {
  name: 'App',
  data: () => ({
    tab: 'Slots'
  }),
}
</script>
<style src="@/assets/styles.css"></style>